import { SyncOutlined } from '@ant-design/icons';
import Button from '../../components/Button';
import { H5 } from '../../styles/fonts';
import { Fields } from './styles';

interface SegmentationCountProps {
  handleSegmentationCount: () => void;
  segmentationCount: number;
}

const SegmentationCount = ({
  handleSegmentationCount,
  segmentationCount,
}: SegmentationCountProps) => {
  return (
    <Fields>
      <div style={{ display: 'flex', gap: '4px' }}>
        <H5 weight="regular">Estimativa:</H5>
        <H5 weight="bold">{segmentationCount}</H5>
        <H5 weight="bold">contatos</H5>
      </div>
      <Button
        type="default"
        icon={<SyncOutlined />}
        size="large"
        onClick={() => handleSegmentationCount()}
      >
        {segmentationCount > 0 ? '' : 'Estimar número de contatos'}
      </Button>
    </Fields>
  );
};

export default SegmentationCount;
