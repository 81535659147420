import React, { useMemo } from 'react';
import { H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';

import { Content02, Row011, Data012, Data022 } from './styles';

interface DistributionData {
  key: string;
  documentCount: number;
  percent: number;
}

interface RegistrationStatusProps {
  estimatedRevenue: DistributionData[];
  companySize: DistributionData[];
}

const EstimatedRevenueTab = ({
  estimatedRevenue,
  companySize,
}: RegistrationStatusProps) => {
  const renderNoDataMessage = () => <H5 weight="regular">Sem registros</H5>;

  const EstimatedRevenueChart = () => {
    const config = useMemo(() => {
      if (estimatedRevenue.length === 0) return null;
      return {
        data: estimatedRevenue.map(item => ({
          description: item.key,
          percentage: item.percent,
        })),
        xField: 'percentage',
        yField: 'description',
        barWidthRatio: 0.3,
        height: 450,
        width: 250,
        animation: false,
        meta: {
          description: { alias: 'Situação' },
          percentage: { alias: 'Porcentagem (%)' },
        },
      };
    }, [estimatedRevenue]);

    if (!config) return renderNoDataMessage();
    return <LineBar {...config} />;
  };

  const CompanySizeChart = () => {
    const config = useMemo(() => {
      if (companySize.length === 0) return null;
      return {
        data: companySize.map(item => ({
          description: item.key,
          percentage: item.percent,
        })),
        xField: 'percentage',
        yField: 'description',
        barWidthRatio: 0.3,
        height: 450,
        width: 250,
        animation: false,
        meta: {
          description: { alias: 'Situação' },
          percentage: { alias: 'Porcentagem (%)' },
        },
      };
    }, [companySize]);

    if (!config) return renderNoDataMessage();
    return <LineBar {...config} />;
  };

  return (
    <Content02>
      <Row011>
        <Data022>
          <H5 weight="medium">Faturamento Presumido</H5>
          <EstimatedRevenueChart />
        </Data022>
        <Data012>
          <H5 weight="medium">Porte</H5>
          <CompanySizeChart />
        </Data012>
      </Row011>
    </Content02>
  );
};

export default EstimatedRevenueTab;
