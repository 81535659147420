import React from 'react';
// import { stringToNumber } from '../../../../helpers/numberFormatter';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { H5 } from '../../../../styles/fonts';

import { Content04, Row019, Data013, ColumnBarTab } from './styles';

interface FinancialRiskProps {
  financialRisks: {
    description: string;
    quantity: number;
    percentage: number;
  }[];
}

const FinancialRiskTab = ({ financialRisks }: FinancialRiskProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  const FinancialRiskBar = () => {
    const data = [...financialRisks].sort(
      (a, b) => b.percentage - a.percentage,
    ); // Ordenar do maior para o menor
    const config = {
      data,
      xField: 'description',
      yField: 'percentage',
      height: 400,
      animation: false,

      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        type: {
          alias: 'risco',
        },
        quantity: {
          alias: 'quantidade',
        },
      },
    };
    return <ColumnBarTab {...config} />;
  };

  return (
    <>
      <Content04>
        <Row019>
          <Data013>
            <H5 weight="medium">
              {tModalDescriptiveAnalysis('psychometric_financial_risk')}
            </H5>
            <FinancialRiskBar />
          </Data013>
        </Row019>
      </Content04>
    </>
  );
};

export default FinancialRiskTab;
