import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import IconesMeuPlano from '../../assets/icones_meu_plano.svg';
import IconesUltimoAcesso from '../../assets/icones_ultimo_acesso.svg';
import IconesMeusContatos from '../../assets/icones_meus_contatos.svg';
import IconesMinhasConexoes from '../../assets/icones_minhas_conexoes.svg';
import { H4 } from '../../styles/fonts';
import { saveFacebookToken } from '../../helpers/localStorage';
import {
  Body1,
  IconCard,
  PageHeader,
  Row01,
  Section,
  SystemSettings,
  IconCardContent,
  SkeletonCard,
} from './styles';
import ProgressCard from '../../components/ProgressCard';
import { useGetFacebookTokenQuery } from '../../services/facebookAuth';
import { useGetDashboardDataQuery } from '../../services/dashboard';

const Home = () => {
  const { data: facebookLongToken } = useGetFacebookTokenQuery();
  const { data: files, isFetching } = useGetDashboardDataQuery({});
  const [userData, setUserData] = useState<any[]>([]);
  const [greeting, setGreeting] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [cachedFiles, setCachedFiles] = useState<any[]>([]);

  const processFiles = (array: any[]) => {
    const pfData = array.filter((item: any) => item.customerType === 'PF');
    const pjData = array.filter((item: any) => item.customerType === 'PJ');
    setUserData([...pfData, ...pjData]);
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return 'Bom dia';
    if (currentHour < 18) return 'Boa tarde';
    return 'Boa noite';
  };

  useEffect(() => {
    if (facebookLongToken) {
      saveFacebookToken(facebookLongToken.accessToken as string);
    }
  }, [facebookLongToken]);

  useEffect(() => {
    const cached = JSON.parse(localStorage.getItem('files') || 'null');
    if (cached) {
      setCachedFiles(cached);
      processFiles(cached);
    }
  }, []);

  useEffect(() => {
    if (files) {
      localStorage.setItem('files', JSON.stringify(files));
      setCachedFiles(files);
      processFiles(files);
    }
  }, [files]);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth') as string);
    setUserName(authData.name);
    setGreeting(getGreeting());
  }, []);

  const renderCard = (
    title: string,
    value: string | number,
    IconComponent: any,
  ) => (
    <IconCard>
      <IconCardContent>
        <h1>{value?.toLocaleString('pt-BR')}</h1>
        <span>{title}</span>
        <p>
          <img src={IconComponent} alt={title} style={{ width: '40px' }} />
        </p>
      </IconCardContent>
    </IconCard>
  );

  const renderEnrichmentLeadCard = (
    count: number,
    total: number,
    type: string,
    customerType: string,
  ) => (
    <IconCard className="highlighted-card">
      <IconCardContent>
        <h1>
          {count?.toLocaleString('pt-BR')}
          <span> de {total?.toLocaleString('pt-BR')}</span>
        </h1>
        <span>
          {type} {customerType === 'PF' ? 'PF' : 'PJ'}
        </span>
        <p>
          <ProgressCard count={count} total={total} />
        </p>
      </IconCardContent>
    </IconCard>
  );

  return (
    <SystemSettings>
      <PageHeader>
        <H4 weight="medium">
          {greeting}, {userName}
        </H4>
      </PageHeader>

      <Body1>
        <Section>
          <Row01>
            {cachedFiles.length === 0 && isFetching ? (
              <>
                {/* Skeletons com o mesmo tamanho do card real */}
                <Skeleton width="100%" height={129} />
                <Skeleton width="100%" height={129} />
                <Skeleton width="100%" height={129} />
                <Skeleton width="100%" height={129} />
                <SkeletonCard className="highlighted-card">
                  <Skeleton width={900} height={340} />
                </SkeletonCard>
                <SkeletonCard className="highlighted-card">
                  <Skeleton width={900} height={340} />
                </SkeletonCard>
                <SkeletonCard className="highlighted-card">
                  <Skeleton width={900} height={340} />
                </SkeletonCard>
                <SkeletonCard className="highlighted-card">
                  <Skeleton width={900} height={340} />
                </SkeletonCard>
              </>
            ) : (
              userData.map(user => (
                <>
                  {user.customerType === 'PF' && (
                    <>
                      {renderCard(
                        'Meu plano',
                        user.planDescription,
                        IconesMeuPlano,
                      )}
                      {renderCard(
                        'Segmentações',
                        user.segmentations,
                        IconesUltimoAcesso,
                      )}
                      {renderCard(
                        'Meus contatos',
                        user.contacts,
                        IconesMeusContatos,
                      )}
                      {renderCard(
                        'Minhas conexões',
                        user.connections,
                        IconesMinhasConexoes,
                      )}
                    </>
                  )}
                  {renderEnrichmentLeadCard(
                    user.enrichment,
                    user.maxQuantityEnrichment,
                    'Enriquecimento',
                    user.customerType,
                  )}
                  {renderEnrichmentLeadCard(
                    user.leads,
                    user.maxQuantityLeads,
                    'Leads',
                    user.customerType,
                  )}
                </>
              ))
            )}
          </Row01>
        </Section>
      </Body1>
    </SystemSettings>
  );
};

export default Home;
