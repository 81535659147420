import React, { useCallback, useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Menu, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { HelperDate } from '../../helpers/date';

import { useCustomTranslation } from '../../hooks/custom-translation';
import { Body } from '../../styles/fonts';
import Button from '../Button';
import Dropdown from '../Dropdown';
import FormInput from '../FormInput';
import ModalDelete from '../ModalDelete';
import {
  ActionButton,
  ActionTab,
  DownloadButton,
  TableContent,
  TableGroup,
} from './styles';
import { ShowFormMessage } from '../../helpers/message';
import getErrors from '../../helpers/errors';
import FilterIcon from '../FilterIcon';
import {
  useGetPresignedUrlFileExportPotentialQuery,
  useDeleteFileExportPotentialMutation,
  useGetAllFilesExportsPotentialQuery,
} from '../../services/potentialAudienceExport';
import Tooltip from '../Tooltip';

interface MenuDropProps {
  id: string;
}

const ExportsTabPotentialAudience = ({
  refetch,
  setLoader,
}: {
  refetch: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { tContactManagement } = useCustomTranslation(
    'contactManagement',
    'commonWords',
  );

  const [search, setSearch] = useState('');
  const [deleteFileId, setDeleteFileId] = useState('');
  const [downloadFileId, setDownloadFileId] = useState('');
  const [fetchInterval, setFetchInterval] = useState<number>(0);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const [page] = useState(1);

  const {
    data: exportFilesPj,
    refetch: exportsRefetch,
    isLoading: exportsLoading,
  } = useGetAllFilesExportsPotentialQuery(
    {
      top: 6,
      skip: page,
      orderby: { createdUtc: 'desc' },
      filter: search ? [{ filter: { fileName: search }, type: 'contain' }] : [],
      custom: `$expand=type,status,fileimport,segmentation,contactenrichmentrequest($expand=fileimport)`,
    },
    { pollingInterval: fetchInterval },
  );

  const { data: downloadUrl, isSuccess } =
    useGetPresignedUrlFileExportPotentialQuery({
      fileExportId: downloadFileId,
    });

  const [deleteFileExportPotential, configDeleteFileExport] =
    useDeleteFileExportPotentialMutation();

  const showMessageDeleteFileExport = useCallback(() => {
    const { isSuccess: deleteSuccess, isError, error } = configDeleteFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Exportação excluída com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (deleteSuccess) {
      setIsModalDeleteOpen(false);
    }
  }, [configDeleteFileExport]);

  const handleShowModalDelete = useCallback((id: string) => {
    setDeleteFileId(id);
    setIsModalDeleteOpen(true);
  }, []);

  const handleDeleteExport = async () => {
    await deleteFileExportPotential(deleteFileId).unwrap();
    exportsRefetch();
  };

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      exportsRefetch();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleDownload = (id: string) => {
    setDownloadFileId(id);
  };

  useEffect(showMessageDeleteFileExport, [showMessageDeleteFileExport]);

  useEffect(() => {
    if (isSuccess) {
      const { url }: any = downloadUrl;
      window.location.href = url;
    }
  }, [isSuccess, downloadUrl]);

  useEffect(() => {
    const unreadyFiles = exportFilesPj?.data.filter(
      (file: any) => file.statusExportId === 1,
    );

    if (unreadyFiles?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [exportFilesPj?.data]);

  useEffect(() => {
    if (exportsLoading) {
      return setLoader(true);
    }

    return setLoader(false);
  }, [exportsLoading]);

  useEffect(() => {
    if (refetch) {
      exportsRefetch();
    }
  }, [refetch]);

  const tagColor = ['', '', 'warning', 'success', 'error'];

  const tagIcon = [
    <></>,
    <></>,
    <SyncOutlined spin />,
    <CheckCircleOutlined />,
    <></>,
  ];

  const MenuDrop = ({ id }: MenuDropProps) => (
    <Menu
      items={[
        {
          key: '0',
          label: (
            <Button
              type="link"
              onClick={() => handleShowModalDelete(id)}
              style={{ color: 'red' }}
              icon={<DeleteOutlined style={{ color: 'red' }} />}
            >
              {tContactManagement('delete_file')}
            </Button>
          ),
        },
      ]}
    />
  );

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const columnsExportsTable: ColumnProps<any>[] = [
    {
      title: 'Arquivo',
      dataIndex: 'fileName',
      align: 'left',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      render: (text, record) => {
        return <Body>{record?.fileName || '-'}</Body>;
      },
      filterDropdown: () => (
        <FormInput
          placeholder={tContactManagement('name')}
          onChange={handleInputSearch}
        />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: `${tContactManagement('date')}`,
      dataIndex: 'createdUtc',
      align: 'left',
      width: '216px',
      sorter: (a, b) =>
        new Date(a.createdUtc).getTime() - new Date(b.createdUtc).getTime(),
      render: (text: Date) => (
        <Body weight="regular">{HelperDate.formateDate(text)}</Body>
      ),
    },
    {
      title: `${tContactManagement('status')}`,
      dataIndex: 'status',
      align: 'left',
      width: '216px',
      sorter: (a, b) =>
        a.potentialExportStatus.friendlyName.localeCompare(
          b.potentialExportStatus.friendlyName,
        ),
      render: (value, record: any) => {
        const status = record?.potentialExportStatus;

        if (record.messageInfo) {
          return (
            <Tooltip title={record?.messageInfo}>
              <Tag color={tagColor[status?.id]} icon={tagIcon[status?.id]}>
                {status?.friendlyName}
              </Tag>
            </Tooltip>
          );
        }

        return (
          <Tag color={tagColor[status?.id]} icon={tagIcon[status?.id]}>
            {status?.friendlyName}
          </Tag>
        );
      },
    },
    {
      title: `${tContactManagement('actions')}`,
      dataIndex: 'acoes',
      align: 'left',
      width: '216px',
      render: (value, record) => {
        return (
          <ActionTab>
            {record?.statusExportId === 3 ? (
              <DownloadButton
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(record.id)}
              >
                Download
              </DownloadButton>
            ) : null}
            <Dropdown overlay={<MenuDrop id={record.id as string} />}>
              <ActionButton type="default" icon={<MoreOutlined />} />
            </Dropdown>
          </ActionTab>
        );
      },
    },
  ];

  return (
    <TableContent>
      <TableGroup
        dataSource={exportFilesPj?.data}
        columns={columnsExportsTable}
        rowKey="id"
      />
      <ModalDelete
        visible={isModalDeleteOpen}
        onCancel={handleCloseModalDelete}
        title={`${tContactManagement('delete_file')}`}
        deleteButton={`${tContactManagement('delete_file')}`}
        onOk={handleDeleteExport}
      >
        Ao executar a exclusão, todos os registros deste arquivo serão removidos
        do sistema.
      </ModalDelete>
    </TableContent>
  );
};

export default ExportsTabPotentialAudience;
