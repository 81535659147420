/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useCustomTranslation } from '../../../../hooks/custom-translation';
import {
  FileImportPjProps,
  FileImportProps,
  useGetFileImporterByIdQuery,
  useImportFileMutation,
} from '../../../../services/fileImporter';
import message from '../../../Message';
import CardStatus from '../CardStatus';
import { UploadDragAndDrop } from './styles';
import {
  useGetFileImporterPjByIdQuery,
  useLazyGetPresignedUrlFileImportQuery,
  usePostFilePjS3Mutation,
  useUpdateFilePjToS3Mutation,
  useUpdateStatusFilePjMutation,
} from '../../../../services/fileImporterPJ';
import { Feedback } from '../../../../helpers/message';

interface FileDataProps {
  setImportData: any;
  setImporterId: any;
  importData: any | undefined;
  setIsLoadingFile: any;
}

const DragAndDropFile = ({
  importData,
  setImportData,
  setImporterId: setCurrentImporterId,
  setIsLoadingFile,
}: FileDataProps) => {
  const { id } = useParams();
  const [importerId, setImporterId] = useState<string>(id || '');
  const types = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ];
  const { tImportContact } = useCustomTranslation('importContact');
  // const [importFile, { isLoading, isError }] = useImportFileMutation();
  const { data: importer, refetch } = useGetFileImporterPjByIdQuery(
    importerId || '',
    {
      pollingInterval: 2000,
      refetchOnFocus: false,
      skip: !importerId,
    },
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [uuid, setUuid] = useState<string>(() => uuidv4().toString());
  const [postFile, { isLoading, isError }] = usePostFilePjS3Mutation();
  const [updateFilePj] = useUpdateFilePjToS3Mutation();
  const [getPresignPjURL] = useLazyGetPresignedUrlFileImportQuery();
  const [importId, setImportId] = useState('');
  useEffect(() => {
    if (importer) {
      setImportData(importer);
      if (importer?.fileImportStatusPjId === 2) {
        setCurrentImporterId(importer?.id);
        setImporterId('');
      }
    }
  }, [importer, setCurrentImporterId, setImportData]);

  const renameFile = (filename: string) => {
    const sanitizedFilename = filename
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9.-]/g, '');
    return sanitizedFilename.replace('.csv', `-${uuid}.csv`);
  };

  const handleGetPresignURL = useCallback(
    async (file: any, fileImportId: any) => {
      const url = await getPresignPjURL({
        contentType: file.type,
        fileName: renameFile(file.name),
        fileId: fileImportId,
      });
      return url.data.url;
    },
    [getPresignPjURL, renameFile],
  );

  const handleDropFile = useCallback(
    async (options: any) => {
      setIsLoadingFile(true);
      await postFile({
        file: renameFile(options.file.name),
        customerFile: options.file.name,
      })
        .unwrap()
        .then(async response => {
          setImporterId(response?.id);
          const url = await handleGetPresignURL(options.file, response?.id);
          setImportData(response);
          if (url) {
            await updateFilePj({
              preSignedUrl: url,
              file: options.file,
              id: response?.id,
            })
              .unwrap()
              .then(async (e: any) => {
                refetch();
                // const { data: fileImportPj } = getFileImportPj(response?.id);

                setCurrentImporterId(response.id);
                // Feedback.success('Upload concluído com sucesso!');
              })
              .catch(error => {
                Feedback.error('Falha no upload do arquivo.');
              });
          }
        })
        .catch(err => {
          console.log('err: ', err);
          setErrorMessage(
            err?.data?.errors
              ? err?.data?.errors[0]?.errorMessage
              : 'Erro ao fazer upload do arquivo. Tente novamente mais tarde!',
          );
          setIsLoadingFile(false);
        });
    },
    [
      handleGetPresignURL,
      postFile,
      refetch,
      renameFile,
      setCurrentImporterId,
      setImportData,
      setIsLoadingFile,
      updateFilePj,
    ],
  );
  // const handleDropFile = useCallback(
  //   async (options: any) => {
  //     setIsLoadingFile(true);

  //     await postFile({
  //       file: renameFile(options.file.name),
  //     })
  //       .unwrap()
  //       .then(async (res: any) => {
  //         setImportId(res.id);
  //         const url = await handleGetPresignURL(options.file);

  //         if (url) {
  //           await updateFilePj({
  //             preSignedUrl: url,
  //             file: options.file,
  //             id: importId,
  //           })
  //             .unwrap()
  //             .then(async (e: any) => {
  //               console.log('update successful: ', e);
  //               await updateStatusFilePj({ id: importId });
  //               setCurrentImporterId(res.id);
  //               Feedback.success('Upload concluído com sucesso!');
  //             })
  //             .catch(error => {
  //               console.log('Update error: ', error);
  //               Feedback.error('Falha no upload do arquivo.');
  //             });
  //         }
  //       })
  //       .catch(err => {
  //         console.log('Post error: ', err);
  //         Feedback.error('Falha no upload do arquivo.');
  //       });
  //   },
  //   [
  //     change,
  //     handleGetPresignURL,
  //     postFile,
  //     setIsLoadingFile,
  //     updateFilePj,
  //     renameFile,
  //     setCurrentImporterId,
  //   ],
  // );

  useEffect(() => {
    if (importData?.fileImportStatusPjId === 2) {
      setIsLoadingFile(false);
    }
  }, [importData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (importData?.fileImportStatusPjId !== 2) {
        refetch();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [importData, refetch]);

  if (isError) {
    return (
      <CardStatus
        type="error"
        title={errorMessage}
        subtitle={tImportContact('select_other_file')}
      />
    );
  }

  if (importData?.fileImportStatusPjId === 1 || isLoading) {
    return (
      <CardStatus
        type="validating"
        title={tImportContact('wait_valition')}
        subtitle={tImportContact('dont_need_keep_page_open')}
      />
    );
  }

  return (
    <div className="salesbox-upload">
      <UploadDragAndDrop
        accept=".csv, .xlsx"
        showUploadList={false}
        customRequest={handleDropFile}
        beforeUpload={file => {
          if (!types.includes(file.type)) {
            message.error(
              `O arquivo ${file.name} é inválido, os formatos permitidos são: .csv ou .xlsx`,
            );
            return false;
          }
          return true;
        }}
        multiple
        onDrop={e => console.log(e)}
      >
        {!importData && (
          <CardStatus
            type="empty"
            title={tImportContact('drag_and_drop_file')}
            subtitle={tImportContact('search_local_file')}
          />
        )}
        {importData?.fileImportStatusPjId === 2 && (
          <CardStatus
            type="done"
            title={importData?.customerFileName as string}
            subtitle=""
          />
        )}
      </UploadDragAndDrop>
    </div>
  );
};

export default DragAndDropFile;
