import { FileImporterPJRoute } from '../../config/services-route';
import { TagTypes } from '../types';
import { providesList } from '../../helpers/provides';

// import { useGetInfoQuery } from '../auth';
import { ColumnProps } from './file-importer';
import { deepDiveApi } from '../../config/apis/deep-dive-query';

interface PresignedURLProps {
  fileName: string;
  contentType: string;
  fileId: string;
}

const fileImporterPJApi = deepDiveApi.injectEndpoints({
  endpoints: builder => ({
    getPresignedUrlFileImport: builder.query<any, PresignedURLProps>({
      query: ({ fileName, contentType, fileId }) => {
        console.log('fileId PRESIGNED URL: ', fileId);
        console.log(
          'FileImporterPJRoute.getPresignedUrl: ',
          FileImporterPJRoute.getPresignedUrl,
        );
        console.log('fileName: ', fileName);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': contentType,
            // 'x-amz-meta-id': fileId,
          },
          url: `${FileImporterPJRoute.getPresignedUrl}?filename=${fileName}&contenttype=${contentType}&fileImportPjId=${fileId}`,
        };
      },
      providesTags: (response: any) => {
        console.log('response: ', response);
        return providesList(response?.data, TagTypes.UploadLogo);
      },
    }),
    importFilePj: builder.mutation<any, any>({
      query: file => {
        const formData = new FormData();
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        formData.append('File', file);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterPJRoute.uploadFile,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
    getDefaultColumns: builder.query<ColumnProps, void>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterPJRoute.getDefaultColumns,
        };
      },
      transformResponse: (response: any) => {
        const customFields = response.filter(
          (a: any) => a.groupType === 'custom',
        );
        const defaultFields = response.filter(
          (a: any) => a.groupType === 'default',
        );
        return { customFields, defaultFields };
      },
      providesTags: response =>
        providesList(response?.customFields, TagTypes.FileImport),
    }),
    getAllFilesImportsPj: builder.query<any, any>({
      query: () => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: FileImporterPJRoute.getAllDataFileImport,
        };
      },
      providesTags: response =>
        providesList(response?.data, TagTypes.FileImport),
      transformResponse: (response: any): any => {
        return {
          data: response,
          meta: {
            size: response['@odata.count'],
          },
        };
      },
    }),
    updateFilePjToS3: builder.mutation<any, any>({
      query: file => {
        console.log('file UPDATED: ', file);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': file.file.type,
            'x-amz-meta-id': file.id,
          },
          method: 'PUT',
          body: file?.file,
          url: file?.preSignedUrl,
        };
      },
      invalidatesTags: [{ type: TagTypes.ImportRfv, id: 'List' }],
    }),
    postFilePjS3: builder.mutation<any, any>({
      query: ({ file, customerFile }) => {
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            fileName: file,
            customerFileName: customerFile,
          }),
          url: `${FileImporterPJRoute.postFile}`,
        };
      },
      invalidatesTags: [TagTypes.FileImport],
    }),
    updateStatusFilePj: builder.mutation<any, any>({
      query: file => {
        console.log('file: ', file);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
          url: `${FileImporterPJRoute.updateFile}?fileImportId=${file?.id}`,
        };
      },
      invalidatesTags: [TagTypes.FileImport],
    }),
    getFileImporterPjById: builder.query<any, string>({
      query: uid => {
        console.log('uid: ', uid);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);
        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FileImporterPJRoute.getImportPjById}/${uid}`,
        };
      },
      transformResponse: (response: any): any => {
        // Clona o objeto original
        const transformedResponse = { ...response[0] };

        // Converte fileFieldsJson de string JSON para um array de strings e adiciona ao transformedResponse
        if (transformedResponse.fileFieldsJson) {
          transformedResponse.fileFields = JSON.parse(
            transformedResponse.fileFieldsJson,
          );
        }
        console.log('transformedResponse: ', transformedResponse);

        // Retorna o objeto transformado
        return transformedResponse;
      },
    }),
    postCombineFieldsPj: builder.mutation<any, any>({
      query: body => ({
        url: FileImporterPJRoute.postCombineFieldsPj,
        method: 'POST',
        body,
        keepalive: true,
      }),
      invalidatesTags: [{ type: TagTypes.FileImport, id: 'LIST' }],
    }),
    deleteFileImportPj: builder.mutation<any, any>({
      query: id => {
        console.log('id: ', id);
        const authData = JSON.parse(localStorage?.getItem('auth') as string);

        return {
          headers: {
            CustomerId: authData.customer,
          },
          url: `${FileImporterPJRoute.deleteImportById}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: TagTypes.FileExport, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDefaultColumnsQuery,
  useGetAllFilesImportsPjQuery,
  useImportFilePjMutation,
  useLazyGetPresignedUrlFileImportQuery,
  useUpdateFilePjToS3Mutation,
  usePostFilePjS3Mutation,
  useUpdateStatusFilePjMutation,
  useGetFileImporterPjByIdQuery,
  usePostCombineFieldsPjMutation,
  useDeleteFileImportPjMutation,
} = fileImporterPJApi;
