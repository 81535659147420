export const base = 'file-import-pj';

export const getAllDataFileImport = `${base}/data-pj`;
export const getDefaultColumns = `${base}/fields-pj`;
export const uploadFile = `${base}/upload-file-pj`;
export const postFile = `${base}/post-file-pj`;
export const getPresignedUrl = `${base}/pre-signed-url-pj`;
export const updateFile = `${base}`;
export const getImportPjById = `${base}/file-import-pj`;
export const postCombineFieldsPj = `${base}/fileImporterDispatchMessage`;
export const deleteImportById = `${base}/delete-file-import`;
