import React from 'react';
import { H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';
import { Content02, Row011, Data022 } from './styles';

interface DistributionData {
  key: string;
  documentCount: number;
  percent: number;
}

interface TaxRegimeProps {
  taxRegimeDistribution: DistributionData[];
}

const abbreviateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const TaxRegimeTab = ({
  taxRegimeDistribution: regimeTributarioDistribution,
}: TaxRegimeProps) => {
  const renderNoDataMessage = () => <H5 weight="regular">Sem registros</H5>;

  const RegimeDistribution = () => {
    const data = regimeTributarioDistribution;

    if (data.length === 0) return renderNoDataMessage();

    const chartHeight = data.length === 1 ? 100 : 450;

    const config = {
      data: data.map(item => ({
        description: abbreviateText(item.key, 30),
        percentage: item.percent,
      })),
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.6,
      columnWidthRatio: 0.8,
      height: chartHeight,
      width: 470,
      animation: false,
      padding: [30, 20, 30, 200],
      meta: {
        description: { alias: 'NATUREZA JURÍDICA' },
        percentage: {
          alias: 'Porcentagem (%)',
          formatter: (val: number) => `${val}%`,
        },
      },
      tooltip: {
        formatter: (datum: any) => ({
          name: 'Porcentagem',
          value: `${datum.percentage}%`,
        }),
      },
    };

    return <LineBar {...config} />;
  };

  return (
    <Content02>
      <Row011>
        <Data022>
          <H5 weight="medium">Distribuição da Natureza Jurídica</H5>
          <RegimeDistribution />
        </Data022>
      </Row011>
    </Content02>
  );
};

export default TaxRegimeTab;
