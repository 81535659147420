import React from 'react';

import { useCustomTranslation } from '../../../../hooks/custom-translation';
import { H5 } from '../../../../styles/fonts';
import { LineBar } from '../../../Bar/LineBar/styles';

import { Content02, Row011, Data012, Data022 } from './styles';

interface IncomeBracketsProps {
  income: {
    householdIncomes: {
      description: string;
      quantity: number;
      percentage: number;
    }[];
    presumedIndividualIncomes: {
      description: string;
      quantity: number;
      percentage: number;
    }[];
  };
}

const IncomeBracketsTab = ({ income }: IncomeBracketsProps) => {
  const { tModalDescriptiveAnalysis } = useCustomTranslation(
    'modalDescriptiveAnalysis',
  );

  const PresumedIncome = () => {
    const data = [...income.presumedIndividualIncomes].sort(
      (a, b) => b.percentage - a.percentage,
    ); // Ordenar do maior para o menor
    const config = {
      data,
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.4,
      height: 400,
      width: 250,
      animation: false,

      meta: {
        salary: {
          alias: '',
        },
        percentage: {
          alias: 'porcentagem',
        },
      },
    };
    console.log(data);
    return <LineBar {...config} />;
  };

  const HouseholdIncome = () => {
    const data = [...income.householdIncomes].sort(
      (a, b) => b.percentage - a.percentage,
    ); // Ordenar do maior para o menor
    const config = {
      data,
      xField: 'percentage',
      yField: 'description',
      barWidthRatio: 0.4,
      height: 400,
      width: 250,
      animation: false,

      meta: {
        description: {
          alias: '',
        },
        percentage: {
          alias: 'porcentagem',
        },
      },
    };
    return <LineBar {...config} />;
  };

  return (
    <>
      <Content02>
        <Row011>
          <Data012>
            <H5 weight="medium">
              {tModalDescriptiveAnalysis('presumed_income')}
            </H5>

            <PresumedIncome />
          </Data012>
          <Data022>
            <H5 weight="medium">
              {tModalDescriptiveAnalysis('family_income')}
            </H5>
            <HouseholdIncome />
          </Data022>
        </Row011>
      </Content02>
    </>
  );
};

export default IncomeBracketsTab;
